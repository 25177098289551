import type { HttpExtra, RawLocationHistoryAvailability } from '@op/services'
import {
	fetchLocationHistory,
	LOCATION_HISTORY_POLL_TS,
	locationHistoryDate$,
	locationHistoryTs$,
	setActiveLocationHistoryDate,
	sortedFilteredChildIds$,
} from '@op/services'
import { toParamsURL } from '@op/util'
import { createAsyncThunk } from '../createAsyncThunk'
import type { OPThunkP } from '../types'

export const setLocHistoryDateAndFetch =
	(date: string): OPThunkP =>
	async (dispatch, getState) => {
		if (date === locationHistoryDate$(getState())) return

		dispatch(setActiveLocationHistoryDate(date))

		await dispatch(fetchAllLocationHistory(date)).unwrap()
	}

export const fetchAllLocationHistory = createAsyncThunk(
	'fetchAllLocationHistory',
	async (date: string, { dispatch, getState }) => {
		await Promise.all(
			sortedFilteredChildIds$(getState()).map((userId) =>
				dispatch(fetchLocationHistory({ date, userId })).unwrap(),
			),
		)
	},
)

export const pollAllLocationHistory = createAsyncThunk(
	'pollAllLocationHistory',
	async (_: void, { dispatch, getState }) => {
		const state = getState()
		const locationHistoryDate = locationHistoryDate$(state)
		const locationHistoryTs = locationHistoryTs$(state)

		// We poll every 15 minutes
		if (!locationHistoryDate || Date.now() < locationHistoryTs + LOCATION_HISTORY_POLL_TS) return

		const sortedFilteredChildIds = sortedFilteredChildIds$(state)

		await Promise.all(
			sortedFilteredChildIds.map((userId) =>
				dispatch(fetchLocationHistory({ date: locationHistoryDate, userId })).unwrap(),
			),
		)
	},
)

type FetchLocationHistoryAvailability = HttpExtra & {
	readonly userId: string
}

export const fetchLocationHistoryAvailability = /*@__PURE__*/ createAsyncThunk(
	'fetchLocationHistoryAvailability',
	async ({ userId, ...extra }: FetchLocationHistoryAvailability, { dispatch, extra: { http } }) => {
		const result = await dispatch(
			http.get<RawLocationHistoryAvailability>(
				toParamsURL('/location_info', { user_id: userId }),
				extra,
			),
		)
		return result
	},
)
