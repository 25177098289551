import { isAuthenticated$ } from '@op/services'
import cls from 'classnames'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { NavBar } from '../../widgets'
import { DemoBottomSheetView } from './DemoBottomSheetView'
import { DemoButtonsView } from './DemoButtonsView'
import { DemoColorsView } from './DemoColorsView'
import { DemoDeviceView } from './DemoDeviceView'
import { DemoFormsView } from './DemoFormsView'
import { DemoModalsView } from './DemoModalsView'
import { DemoRootView } from './DemoRootView'
import { DemoSizingView } from './DemoSizingView'
import { DemoTypographyView } from './DemoTypographyView'

export const DemoPage = () => {
	const isAuth = useSelector(isAuthenticated$)

	// NOTE: Empty Nav Bar is just to close the mobile nav when logged in.
	return (
		<div className={cls(isAuth && '-mt-16 lg:-mt-20')}>
			<NavBar>{''}</NavBar>
			<Switch>
				<Route component={DemoRootView} path="/demo" exact />
				<Route component={DemoBottomSheetView} path="/demo/bottom-sheet" />
				<Route component={DemoButtonsView} path="/demo/buttons" />
				<Route component={DemoColorsView} path="/demo/colors" />
				<Route component={DemoDeviceView} path="/demo/devices" />
				<Route component={DemoFormsView} path="/demo/forms" />
				<Route component={DemoModalsView} path="/demo/modals" />
				<Route component={DemoSizingView} path="/demo/sizing" />
				<Route component={DemoTypographyView} path="/demo/typography" />
				<Redirect to="/demo" />
			</Switch>
		</div>
	)
}
