import { curry, notEmpty } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import { accountAccess$, activeChildId$, rawManagedDevices$ } from '../reducers'
import type { ManagedDevice } from '../types'
import {
	FeatureMinAppVersion,
	FeatureMinOSVersion,
	hasMinFeatVer,
	hasMinOSVer,
	hasPremCapableIOSDevice,
	isAndroidDevice,
	isIOSDevice,
	isOutdatedAndroidDevice,
	isSupervisedDevice,
	isVewCapableDevice,
	mapRawToDevice,
} from '../types'

const createHasFeatureCapableDeviceSelector = (minFeatVer: number, reqIOSChildApp = true) =>
	createSelector(activeUserDevices$, (d) =>
		d.some(_isFeatureCapableDevice(minFeatVer, reqIOSChildApp)),
	)
const _isMinOSAndroidDevice = curry(
	(minOSVer: number, d: ManagedDevice) => isAndroidDevice(d) && hasMinOSVer(minOSVer, d),
)
const _isMinOSIOSDevice = curry(
	(minOSVer: number, d: ManagedDevice) => isIOSDevice(d) && hasMinOSVer(minOSVer, d),
)

const _isFeatureCapableDevice =
	(minFeatVer: number, reqIOSChildApp: boolean) =>
	<T extends ManagedDevice>(d: T) =>
		isAndroidDevice(d) ? !isOutdatedAndroidDevice(minFeatVer, d)
		: isIOSDevice(d) ? hasPremCapableIOSDevice(d, reqIOSChildApp)
		: false

export const isVewCapableAndroidDevice = (minFeatVer: number, minOSVer: number, d: ManagedDevice) =>
	isAndroidDevice(d) && isVewCapableDevice(minFeatVer, minOSVer, d)

export const isVewCapableIOSDevice = (minFeatVer: number, minOSVer: number, d: ManagedDevice) =>
	isIOSDevice(d) && hasPremCapableIOSDevice(d, true) && isVewCapableDevice(minFeatVer, minOSVer, d)

const isSupervisedVewCapableIOSDevice = (minOSVer: number, d: ManagedDevice) =>
	isIOSDevice(d) && hasPremCapableIOSDevice(d, false) && hasMinOSVer(minOSVer, d)

const _mapDevicesToUser = (userId: string, devices: ManagedDevice[]) =>
	devices.filter((d) => (d.users || []).includes(userId))

export const managedDevices$ = /*@__PURE__*/ createSelector(
	rawManagedDevices$,
	(d): ManagedDevice[] => d.map(mapRawToDevice),
)

// Gets devices that have device location enabled
export const managedGeoDevices$ = /*@__PURE__*/ createSelector(managedDevices$, (dvs) =>
	dvs.filter((d) => d.device_location_active),
)

// "Valid" managed devices are for non-retired devices that have users
export const validManagedDevices$ = /*@__PURE__*/ createSelector(managedDevices$, (dvs) =>
	dvs.filter((d) => !d.retired && notEmpty(d.users) && !d.management_removed),
)

// Returns devices that have not been retired
export const nonRetiredManagedDevices$ = /*@__PURE__*/ createSelector(managedDevices$, (dvs) =>
	dvs.filter((d) => !d.retired && notEmpty(d.users)),
)

export const hasValidManagedDevices$ = /*@__PURE__*/ createSelector(validManagedDevices$, notEmpty)

// Determines whether user has reached max devices limit
export const hasMaxDevices$ = /*@__PURE__*/ createSelector(
	validManagedDevices$,
	accountAccess$,
	(d, aa) => d.length >= aa.devices_per_account,
)

// Filters on devices belonging to the active user
export const activeUserDevices$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	managedDevices$,
	_mapDevicesToUser,
)

const _activeUserIOSDevices$ = /*@__PURE__*/ createSelector(activeUserDevices$, (d) =>
	d.filter(isIOSDevice),
)

// Filters on valid devices belonging to active user
export const activeUserValidDevices$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	validManagedDevices$,
	_mapDevicesToUser,
)

export const activeUserHasValidDevice$ = /*@__PURE__*/ createSelector(
	activeUserValidDevices$,
	notEmpty,
)

// Devices that can support app rules for active user
export const activeUserAppRulesDevices$ = /*@__PURE__*/ createSelector(activeUserDevices$, (d) =>
	d.filter(_isFeatureCapableDevice(FeatureMinAppVersion.APP_RULES, false)),
)

export const activeUserHasRestrixCapableAndroidDevice$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(devices) =>
		devices.some(
			(d) => isAndroidDevice(d) && hasMinFeatVer(FeatureMinAppVersion.RESTRIX_ANDROID, d),
		),
)

export const activeUserCanLimitAndroidAdTracking$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(devices) =>
		devices.some(
			(d) =>
				_isMinOSAndroidDevice(FeatureMinOSVersion.ANDROID_AD_TRACKING, d) &&
				hasMinFeatVer(FeatureMinAppVersion.RESTRIX_ANDROID, d),
		),
)

export const activeUserCanDisableAndroidAirplaneMode$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(devices) =>
		devices.some(
			(d) =>
				_isMinOSAndroidDevice(FeatureMinOSVersion.ANDROID_DISABLE_AIRPLANE, d) &&
				hasMinFeatVer(FeatureMinAppVersion.RESTRIX_ANDROID, d),
		),
)

export const canActiveUserLockAndroidNetwork$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(devices) =>
		devices.some(
			(d) =>
				_isMinOSAndroidDevice(FeatureMinOSVersion.ANDROID_NETWORK_LOCK, d) &&
				hasMinFeatVer(FeatureMinAppVersion.RESTRIX_NETWORK_LOCK, d),
		),
)

export const activeUserHasAllowanceCapableDevice$ =
	/*@__PURE__*/ createHasFeatureCapableDeviceSelector(FeatureMinAppVersion.ALLOWANCE)
export const activeUserHasAppRulesCapableDevice$ =
	/*@__PURE__*/ createHasFeatureCapableDeviceSelector(FeatureMinAppVersion.ALLOWANCE, false)
export const activeUserHasIOSDevice$ = /*@__PURE__*/ createSelector(
	_activeUserIOSDevices$,
	notEmpty,
)
export const activeUserHasRestrixCapableDevice$ =
	/*@__PURE__*/ createHasFeatureCapableDeviceSelector(FeatureMinAppVersion.RESTRIX)
export const activeUserHasSupervisedDevice$ = /*@__PURE__*/ createSelector(
	_activeUserIOSDevices$,
	(d) => d.some(isSupervisedDevice),
)
export const activeUserHasVewAndroidMinOS$ = /*@__PURE__*/ createSelector(activeUserDevices$, (d) =>
	d.some(_isMinOSAndroidDevice(FeatureMinOSVersion.VEW_ANDROID)),
)
export const activeUserHasVewCapableDevices$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(devices) =>
		devices.some(
			(d) =>
				isVewCapableAndroidDevice(
					FeatureMinAppVersion.VEW_ANDROID,
					FeatureMinOSVersion.VEW_ANDROID,
					d,
				) || isVewCapableIOSDevice(FeatureMinAppVersion.VEW_IOS, FeatureMinOSVersion.VEW_IOS, d),
		),
)
export const activeUserHasSupervisedVewCapableIOSDevice$ = /*@__PURE__*/ createSelector(
	activeUserDevices$,
	(d) => d.length === 1 && isSupervisedVewCapableIOSDevice(FeatureMinOSVersion.VEW_IOS, d[0]),
)
export const activeUserHasVewIOSMinOS$ = /*@__PURE__*/ createSelector(activeUserDevices$, (d) =>
	d.some(_isMinOSIOSDevice(FeatureMinOSVersion.VEW_IOS)),
)
export const activeUserNeedsSupervision$ = /*@__PURE__*/ createSelector(
	_activeUserIOSDevices$,
	notEmpty,
)

export const activeUserHasDevicePaired$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	nonRetiredManagedDevices$,
	(activeUserId, nonRetiredDevices) => {
		if (!activeUserId) return false

		return nonRetiredDevices.some((d) => d.users.includes(activeUserId))
	},
)

export const devicesForUserSelector = () =>
	createSelector(
		nonRetiredManagedDevices$,
		(_: any, userId: string) => userId,
		(devices, userId) => devices.filter((d) => d.users.includes(userId)),
	)
