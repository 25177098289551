import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { pick, timeoutPromise } from '@eturi/util'
import {
	accountEmail$,
	accountToken$,
	installChildApp,
	isAccountTokenValid,
	managedDevices$,
	rawFilteredChildrenSortByCreated$,
	resetAction,
} from '@op/services'
import { toParamsURL } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { createSliceTransformer, stripPayload } from 'rtk-slice-transformer'
import { v4 } from 'uuid'
import { createAsyncThunk } from '../createAsyncThunk'
import { ACCOUNT_TOKEN_EXPIRY_MINUTES, getEndpoints } from '../env'
import type { OPThunk } from '../types'

export type PairState = {
	readonly pairChildName: Maybe<string>
	readonly pairId: Maybe<string>
	readonly wasMdmDownloaded: boolean
}

export type WithPairState = {
	readonly pair: PairState
}

const initialState: PairState = {
	pairChildName: null,
	pairId: null,
	wasMdmDownloaded: false,
}

export const pairSlice = /*@__PURE__*/ createSlice({
	name: 'pair',
	initialState,
	reducers: {
		setPairChildName(s, a: PayloadAction<Maybe<string>>) {
			s.pairChildName = a.payload
		},

		setPairId(s, a: PayloadAction<Maybe<string>>) {
			s.pairId = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(checkDevicePairStatus.fulfilled, (s, a) => {
				// If pair status is a success, set MDM downloaded back to default
				if (a.payload) s.wasMdmDownloaded = false
			})
			.addCase(openPairCertWindow.pending, (s) => {
				s.wasMdmDownloaded = false
			})
			.addCase(openPairCertWindow.fulfilled, (s, a) => {
				s.wasMdmDownloaded = a.payload
			})
			.addCase(pairExpiredLogout.fulfilled, (s, { payload }) => {
				s.pairChildName = payload.pairChildName
				s.pairId = payload.pairId
			}),
})

export const { setPairChildName, setPairId } = pairSlice.actions

export const pairSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	pairSlice,
	(s) => pick(s, ['pairId', 'wasMdmDownloaded']),
	(a) => (setPairChildName.match(a) ? stripPayload(a) : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

const getPairingUrl =
	(url: string, generatePairId = false, otherArgs: Record<string, unknown> = {}): OPThunk<string> =>
	(dispatch, getState) => {
		const state = getState()

		if (!dispatch(isAccountTokenValid(ACCOUNT_TOKEN_EXPIRY_MINUTES)))
			throw new Error('Invalid account token')

		const email = accountEmail$(state)
		const pair_id = generatePairId ? v4() : pairId$(state)
		const token = accountToken$(state)

		if (!(email && pair_id)) throw new Error(`Missing email or pair_id: ${pair_id}`)
		if (generatePairId) dispatch(setPairId(pair_id))

		return toParamsURL(url, { email, pair_id, token, ...otherArgs })
	}

type PairConfirmationRes = {
	readonly device_paired: boolean
}

export const checkDevicePairStatus = createAsyncThunk(
	'pair/checkDevicePairStatus',
	async (_, { dispatch, extra: { http } }) => {
		sentryBreadcrumb('Check device pair status')

		try {
			const url = dispatch(getPairingUrl(`${getEndpoints()!.hfbk}/confirm`))
			const data = await dispatch(
				http.get<Maybe<PairConfirmationRes>>(url, { isUnauthenticated: true }),
			)

			return Boolean(data?.device_paired)
		} catch (e) {
			sentryError(e)
			return false
		}
	},
)

export const installChildAppPairing = /*@__PURE__*/ createAsyncThunk(
	'pair/installChildAppPairing',
	async (_, { dispatch, getState }) => {
		const state = getState()

		// First we find the child's user_id from selected pair child name
		const children = rawFilteredChildrenSortByCreated$(state)
		const pairChildName = pairChildName$(state)

		if (!pairChildName) return false

		const pairChild = find(children, { user_name: pairChildName })

		if (!pairChild) return false

		// Find all the devices to which this user id assigned
		const childDevices = managedDevices$(state).filter((d) => d.users?.includes(pairChild.user_id))

		// Of the users assigned devices, find the one with the `pair_id` that matches
		const pairDevice = find(childDevices, { pair_id: pairId$(state) })

		// If we don't find a device with the correct `pair_id`, it's a fail.
		if (!pairDevice) return false

		await dispatch(
			installChildApp({
				deviceId: pairDevice.device_id,
				userId: pairChild.user_id,
			}),
		).unwrap()

		return true
	},
)

export const openPairCertWindow = createAsyncThunk(
	'pair/openCertWindow',
	async (childName: string, { dispatch }) => {
		sentryBreadcrumb('Open pair cert window')

		try {
			const url = dispatch(
				getPairingUrl(`${getEndpoints()!.hfbk}/pairing`, true, { child_name: childName }),
			)

			window.open(url, '_self')

			return Promise.resolve(true)
		} catch (e) {
			sentryError(e)
			return Promise.resolve(false)
		}
	},
)

export const pairExpiredLogout = createAsyncThunk(
	'pair/expiredLogout',
	async (redirect: string, { dispatch, getState }) => {
		const state = getState()
		const pairId = pairId$(state)
		const pairChildName = pairChildName$(state)

		dispatch(resetAction('pair_token_expiry'))
		// NOTE: We set a redirect for /pair after we logout. This way we redirect
		//  back to pairing after the token has been refreshed. However the logout
		//  flow indirectly sets the redirect to login so that the user can log in
		//  again. It is because of this that we must make our set redirect to pair
		//  happen after the logout flow. To do this we wrap it in a timeout which
		//  allows us to set the redirect like we want.
		await timeoutPromise()

		return { pairChildName, pairId, redirect }
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithPairState>(s: T) => s.pair

export const pairId$ = /*@__PURE__*/ createSelector(state$, (s) => s.pairId)
export const pairChildName$ = /*@__PURE__*/ createSelector(state$, (s) => s.pairChildName)
export const wasMdmDownloaded$ = /*@__PURE__*/ createSelector(state$, (s) => s.wasMdmDownloaded)
