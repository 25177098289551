import { useFn, useMounted, usePrevious } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import type { ComponentType, FC } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useStore } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import type { OPResolver } from '../common'
import { isResolverRedirect } from '../common'
import { useNavTo } from '../hooks'
import type { AppStore } from '../types'
import { LoadingSpinner } from '../widgets'

type ResolverCtx = {
	isParentResolving(): boolean
}
const ResolverCtx = createContext<ResolverCtx>(null as any)
const ResolverProvider = ResolverCtx.Provider

const DEFAULT_IS_PARENT_RESOLVING = () => false

/**
 * Wraps a component, so it renders a fallback until its dependencies resolve
 * @param resolve Resolver function
 * @param Component Component to render
 * @param forcePendingResolve Whether to force a "pending" state every time
 *   a resolve occurs. By default, the pending fallback state is only shown the
 *   first time a component is mounted, so that transitions between states are
 *   seamless. But sometimes we might want to wait on state every time.
 * @param Fallback Fallback component. Defaults to `LoadingSpinner`
 */
export const componentResolver = <P extends Record<string, unknown> = Record<string, unknown>>(
	resolve: OPResolver,
	Component: FC<P>,
	forcePendingResolve = false,
	Fallback: ComponentType<any> = LoadingSpinner,
): FC<P> => {
	let hasCopiedStatics = false

	const ResolverComponent: FC<P> = (p) => {
		/**
		 * Copy the static fields if they haven't yet. Since this wrapped version
		 * is what is returned and interacted with, the copying goes from wrapper
		 * to wrapped. In the case of the `displayName`, we annotate the wrapper.
		 */
		if (!hasCopiedStatics) {
			Object.assign(Component, ResolverComponent)
			ResolverComponent.displayName = `(Wrapped) ${
				Component.displayName || Component.name || 'Anonymous'
			}`
			hasCopiedStatics = true
		}

		const store = useStore() as AppStore
		const navTo = useNavTo()
		const location = useLocation()
		const resolverCtx = useContext(ResolverCtx)
		/**
		 * NOTE: We currently only have a Parent -> Child relationship in our
		 *  resolver hierarchy (not Grandparent -> Parent/Child -> Child). If we
		 *  ever do have this relationship, we might very well need to redefine the
		 *  definition of "Parent" to be something that _has a child_, rather than,
		 *  as we currently define it, a component that has no parent context.
		 *  -
		 *  This would probably involve a child registering with with parent through
		 *  context. This is more complicated obviously, and since it's currently
		 *  not needed, we can safely ignore it. However, this comment is added
		 *  to document knowledge of the possibility and potential solution in case
		 *  this needs to be revisited.
		 */
		const isChild = Boolean(resolverCtx)

		const isParentResolving = resolverCtx?.isParentResolving || DEFAULT_IS_PARENT_RESOLVING

		/**
		 * Whether we're pending a resolve. This is always true for first render
		 * (see below)
		 */
		const [isPendingResolve, setPendingResolve] = useState(true)

		/**
		 * Whether this component itself is currently resolving. This is different
		 * from pending resolving in that is is set for all (parent / child)
		 * components and doesn't guarantee that we're showing a loading spinner.
		 * For parents this is what passed to context `isParentResolving`.
		 */
		const [isResolving, setResolving] = useState(false)

		/**
		 * Renders and `waitCount` are used by children to make sure that they
		 * always render after the parent has had a change to do its resolve.
		 */
		const [resolveCount, setResolveCount] = useState(0)
		const [waitCount, setWaitCount] = useState(-1)
		const lastWaits = usePrevious(waitCount)
		const match = useRouteMatch()
		const lastMatch = usePrevious(match)
		const isMounted = useMounted()

		/**
		 * Match change is determined using deep equality since this object will
		 *  change without the contents changing. We only watch to trigger a
		 *  resolver for a match change when actual values change.
		 */
		const hasMatchChange = !isEqual(match, lastMatch)

		/**
		 * We should resolve if we're pending, if the path match has changed, AND
		 * if neither the component nor parent component are currently resolving.
		 */
		const shouldResolve =
			(isPendingResolve || hasMatchChange) && !(isResolving || isParentResolving())

		useEffect(() => {
			/**
			 * If:
			 * - This is a child AND
			 * - We've incremented `waitCount` to match `resolveCount` AND
			 * - We did this increment on the last event loop
			 *
			 * Then:
			 * - We're in a state where the child should resolve in the next event
			 *   loop. Thus, we set it to pending resolve, so `shouldResolve` will be
			 *   true next render pass.
			 */
			const shouldSetChildResolving =
				isChild && resolveCount === waitCount && waitCount !== lastWaits

			if (shouldSetChildResolving && !isPendingResolve) setPendingResolve(true)

			/**
			 * Don't continue if we shouldn't resolve this time. Either we're not
			 * resolving, the path match hasn't changed (a change in `waitCount` only
			 * could cause this), or the parent is resolving and we should wait. If we
			 * shouldn't be resolving due to parent resolving, this will re-run when
			 * parent finishes resolving. If we shouldn't resolve b/c we're not
			 * pending a resolve, setting resolving for a child with
			 * `waitCount` / `resolveCount` mismatch, `setPendingResolve` above will
			 * change that on the next loop as well.
			 */
			if (!shouldResolve) return

			/**
			 * We only check `resolveCount` vs `waitCount` if we're a child. If we're
			 * a child and `resolveCount` and `waitCount` are different, we need to
			 * wait one event loop.
			 */
			if (isChild && resolveCount !== waitCount) return setWaitCount(resolveCount)

			const resolver = async () => {
				setResolving(true)
				if (forcePendingResolve) setPendingResolve(true)

				try {
					await resolve(store, location, match)
				} catch (e) {
					if (isResolverRedirect(e)) {
						console.log(`Redirecting to '${e.path}'`)

						navTo(e.path, true)

						if (!isMounted()) return

						// NOTE: We need to set resolving after redirect. Otherwise, the
						//  next time `shouldResolve` may be true when it shouldn't be.
						return setResolving(false)
					}

					sentryError(e, 'Resolver failed')
				}

				if (!isMounted()) return

				setPendingResolve(false)
				// Only set the `resolveCount` for children
				isChild && setResolveCount(resolveCount + 1)
				// NOTE: Again as above, set resolving is last.
				setResolving(false)
			}

			resolver()
			// NOTE: We only watch `waitCount` (not `resolveCount`) b/c adding
			//  `resolveCount` would result in an infinite loop. `resolveCount` only
			//  update as related to `waitCount` anyways, so this is fine.
		}, [shouldResolve, waitCount])

		/**
		 * This is the main display logic decision and it's summarized as follows:
		 *
		 * 1. All `ResolverComponent`s will are set to pending resolver and show
		 *   fallback on the initial render (seen as `useState(true)`).
		 *
		 * 2. For subsequent `resolveCount` (after the first resolve runs), parents
		 *   do not set a pending resolve state (even when they are resolving).
		 *
		 * 3. For subsequent `resolveCount` as above, children _always_ show a
		 *   fallback loading state when either their parent is resolving, or they
		 *   themselves are resolving.
		 */
		const shouldShowFallback = isPendingResolve || (isChild && isResolving) || isParentResolving()

		return (
			<ResolverProvider value={{ isParentResolving: useFn(() => isResolving) }}>
				{shouldShowFallback ?
					<Fallback />
				:	<Component {...p} />}
			</ResolverProvider>
		)
	}

	return ResolverComponent
}
