import { Glyph, OPLink } from '../../widgets'

export const DemoRootView = () => (
	<div className="is-page-pad-touch">
		<h2 className="mb-5 text-3xl text-center">Demos</h2>
		<ChevronLink link="/demo/bottom-sheet">Bottom Sheet</ChevronLink>
		<ChevronLink link="/demo/buttons">Buttons</ChevronLink>
		<ChevronLink link="/demo/colors">Colors</ChevronLink>
		<ChevronLink link="/demo/devices">Device Details</ChevronLink>
		<ChevronLink link="/demo/forms">Forms</ChevronLink>
		<ChevronLink link="/demo/modals">Modals</ChevronLink>
		<ChevronLink link="/demo/sizing">Sizing</ChevronLink>
		<ChevronLink link="/demo/typography">Text</ChevronLink>
	</div>
)

const ChevronLink = ({ children, link }: { children: string; link: string }) => (
	<OPLink className="flex items-center justify-between border-b p-2 last:border-b-0" to={link}>
		<span className="font-bold text-text">{children}</span>
		<Glyph name="chevron-right" />
	</OPLink>
)
