/**
 * Contains application routes at the root page level
 */

import cls from 'classnames'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
	AccountPage,
	ConnectPage,
	ErrorPage,
	GalleryPage,
	LocatorBasePage,
	LocatorPage,
	LogoutPage,
	ManageBasePage,
	ManagePage,
	MyFamilyPage,
	NotifsPage,
	PromoPage,
	SubscriptionPage,
} from './pages'
import { DemoPage } from './pages/DemoPage'
import {
	ForgotPwPage,
	LoginPage,
	PairPage,
	PwResetPage,
	SignUpPage,
	VerifyEmailPage,
} from './pages/lazy'
import { LoadPage } from './widgets'

/**
 * NOTE: This is distinctly an array and not a fragment because React Router
 *  does not handle fragments well at all. It doesn't traverse them the way
 *  it should and expects every child to be a `Route`. Because of this, if
 *  fragments are used, we'll end up with weird errors, where it says Routes
 *  are going from uncontrolled to controlled, and also the redirect will not
 *  work properly. Anyways, just using an array works fine.
 */
const Fallback =
	process.env.APP_ENV === 'dev' ?
		[
			<Route key={0} component={DemoPage} path="/demo/:page" />,
			<Route key={1} component={DemoPage} exact={true} path="/demo" />,
			<Redirect key={2} exact={true} to="/my-family" from="/my-family/:userId" />,
			<Redirect key={3} from="/" to="/login" />,
		]
	:	[
			<Redirect key={2} exact={true} to="/my-family" from="/my-family/:userId" />,
			<Redirect key={3} from="/" to="/login" />,
		]

export const AppPages = () => (
	<section className={cls('page-wrap')}>
		<LoadPage>
			<Switch>
				<Route path="/error" component={ErrorPage} />
				<Route path="/login" component={LoginPage} />
				<Route path="/logout" component={LogoutPage} />
				<Route path="/forgot" component={ForgotPwPage} />
				<Route path="/reset" component={PwResetPage} />
				<Route path="/verify" component={VerifyEmailPage} />

				<Route component={SignUpPage} path="/signup/:groupId?" />
				<Route component={AccountPage} path="/account/:change?" />
				<Route component={SubscriptionPage} path="/subscription" />
				<Route component={ConnectPage} path="/connect" />
				<Redirect to="/connect" from="/add-child" />
				<Redirect to="/connect" from="/utility" />
				<Route component={MyFamilyPage} path="/my-family" exact />
				<Redirect to="/my-family" from="/devices" />
				<Route component={GalleryPage} path="/gallery/:userId" />
				<Route component={ManageBasePage} path="/manage" exact />
				<Route component={ManagePage} path="/manage/:userId/:feature" />
				<Route component={NotifsPage} path="/notifications/:selectedId?" />
				<Route component={LocatorBasePage} path="/locator" exact />
				<Route component={LocatorPage} path="/locator/:userId" />
				<Route component={PairPage} path="/pair" />
				<Route component={PromoPage} path="/promo" />

				{Fallback}
			</Switch>
		</LoadPage>
	</section>
)
